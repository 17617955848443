<template>
  <div>
    <b-carousel
      v-if="banners.length"
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      style="text-shadow: 1px 1px 2px var(--gray01); border-radius: 10px; margin-top: 30px"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(banner, index) in banners" :key="index">
        <template #img>
          <a
            :href="banner.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="d-block img-fluid w-100 border-banner"
              :src="banner.image_url"
              alt="image slot"
            />
          </a>
        </template>
      </b-carousel-slide>
      
    </b-carousel>

    <div v-else class="height: 258px; width: 100%">
      <b-skeleton height="250px"></b-skeleton>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

import BannerService from "@/services/resources/BannerService";
const serviceBanner = BannerService.build();
import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";
import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      banners: [],
    };
  },
  mounted() {
    setupDB()
      .then(() => {
        this.fetchBanners();
      })
      .catch((error) => {
        this.fetchBanners();
      });
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    }
  },
  methods: {
    openClub() {
      var site_id =
        process.env.VUE_APP_API_HOST === "https://api.gdigital.com.br/"
          ? 53
          : 1;

      var config = {
        headers: {
          Authorization: Cookies.get("access_token"),
        },
      };

      axios
        .get(
          process.env.VUE_APP_API_HOST + "club/login?site_id=" + site_id,
          config
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.success === false) {
            // configurar modal caso n tiver o item
            // $.fancybox.open($('#openNotVerify'), {
            //     touch: false
            // });
          } else {
            window.open(
              response.data.site + "?rdr=" + response.data.string,
              "_blank"
            );
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    async fetchBanners() {
      if(this.isGreenn){
        serviceGlobalSettings
          .read({id:'greenn_banner'})
          .then((resp) => {
            const banner = JSON.parse(resp.meta_value);
            if(!banner){
              return;
            }
            this.banners = [{
              link: banner.url_redirect,
              image_url: banner.upload_path
            }];
          })
        return;
      }
      const cachedData = await getCachedData("fetchBanners");

      if (cachedData) {
        this.banners = cachedData;
      }

      serviceBanner.search()
        .then(r => {
        cacheData("fetchBanners", r);
         this.banners = r;
        })
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
.border-banner {
  border-radius: 10px !important;
}
</style>